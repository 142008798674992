import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import X from '@/assets/img/lp/X.webp'
import Instagram from '@/assets/img/lp/Instagram.webp'
import type { NextPage } from 'next'

type props = {
  className?: string
}

const SNS: NextPage<props> = ({ className = '' }) => {
  return (
    <section className={`w-full bg-white py-8 md:py-16 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row items-center sm:justify-between">
        <div className="w-[257.2px] relative h-[80px] mb-2 sm:mb-0">
          <Link href="/">
            <Image
              src="/Logo.svg"
              alt="FANME Logo"
              layout="fill"
              objectFit="contain"
              objectPosition="left"
            />
          </Link>
        </div>
        <div className="flex flex-col sm:flex-row items-center space-y-6 sm:space-y-0 sm:space-x-8 w-full sm:w-auto">
          <div className="border-[#222] border-2 py-1 px-4 bg-transparent sm:mb-0 w-full sm:w-auto max-w-[257.2px]">
            <span className="text-[20px] font-bold font-sansJP text-[#222]">公式SNS</span>
          </div>
          <div className="flex space-x-8 justify-center w-full sm:w-auto">
            <a
              href="https://x.com/fanme__official"
              target="_blank"
              rel="noopener noreferrer"
              className="relative h-[60px] w-[60px]"
            >
              <Image src={X} alt="X (Twitter) Icon" layout="fill" objectFit="contain" />
            </a>
            <a
              href="https://www.instagram.com/fanme__official/"
              target="_blank"
              rel="noopener noreferrer"
              className="relative h-[60px] w-[60px]"
            >
              <Image src={Instagram} alt="Instagram Icon" layout="fill" objectFit="contain" />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SNS
