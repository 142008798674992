import React from 'react'
import Image, { StaticImageData } from 'next/image'
import Typography from '@/components/atoms/Typography'

type props = {
  talkImage: StaticImageData
  charaImage: StaticImageData
  description: React.ReactNode
}

const FeatureItem: React.FC<props> = ({ talkImage, charaImage, description }) => {
  return (
    <div className="w-full flex flex-col items-center max-w-[350px]">
      <div className="relative w-full h-[130px]">
        <Image src={talkImage} alt="Feature talk" layout="fill" objectFit="contain" />
      </div>
      <div className="relative w-[160px] h-[160px] mb-2">
        <Image src={charaImage} alt="Feature character" layout="fill" objectFit="contain" />
      </div>
      <Typography variant="feature-description" className="text-center leading-[140%]">
        {description}
      </Typography>
    </div>
  )
}

export default FeatureItem
