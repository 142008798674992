import React from 'react'
import Image from 'next/image'
import TitleUnderLine from '@/assets/img/lp/TitleUnderLine.webp'
import type { NextPage } from 'next'

export type FrameComponentType = {
  className?: string
  title: string | React.ReactNode
}

const BlockTitleWithUnderLine: NextPage<FrameComponentType> = ({ className = '', title }) => {
  return (
    <div
      className={`self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-[44px] text-[#222] font-[Roboto] ${className}`}
    >
      <div className="w-full relative tracking-[0.03em] leading-[50px] flex items-center justify-center z-[1] mq450:text-[24px] mq450:leading-[36px] mq800:text-[32px] mq800:leading-[48px]">
        <h2 className="text-[35px] sm:text-[40px] font-black font-sansJP">{title}</h2>
      </div>
      <div className="self-stretch flex flex-row items-start justify-center py-[0px] px-[20px]">
        <div className="relative w-[96px] h-[8px]">
          <Image src={TitleUnderLine} alt="Title underline" layout="fill" objectFit="contain" />
        </div>
      </div>
    </div>
  )
}

export default BlockTitleWithUnderLine
