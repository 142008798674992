import React from 'react'
import Router from 'next/router'
import getConfig from 'next/config'
import BlockTitleWithUnderLine from '@/components/lp/BlockTitleWithUnderLine'
import FeatureItem from '@/components/lp/FeatureItem'
import GradientButton from '@/components/lp/GradientButton'
import Talk1 from '@/assets/img/lp/Talk1.webp'
import Talk2 from '@/assets/img/lp/Talk2.webp'
import Talk3 from '@/assets/img/lp/Talk3.webp'
import Character1 from '@/assets/img/lp/Character1.webp'
import Character2 from '@/assets/img/lp/Character2.webp'
import Character3 from '@/assets/img/lp/Character3.webp'
import { event as gevent } from '@/pages/api/gtag'
import type { NextPage } from 'next'
type props = {
  className?: string
}

const { publicRuntimeConfig } = getConfig()

const Feature: NextPage<props> = ({ className = '' }) => {
  const toSignUp = () => {
    gevent({
      action: 'lp_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }
  return (
    <section className={`w-full bg-white py-8 md:py-16 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8">
        <BlockTitleWithUnderLine
          title={
            <>
              FANMEが
              <br className="sm:hidden" />
              クリエイターに
              <br className="sm:hidden" />
              選ばれる理由
            </>
          }
        />
        <div className="mt-12 flex flex-col xl:flex-row items-center xl:items-start justify-between gap-6 md:gap-2 xl:gap-2">
          <FeatureItem
            talkImage={Talk1}
            charaImage={Character1}
            description={
              <>
                今までできなかったオフラインイベントや
                <br />
                企画、コンテンツ作成のサポートも
                <br />
                行ってます。
              </>
            }
          />
          <FeatureItem
            talkImage={Talk2}
            charaImage={Character2}
            description={
              <>
                FANME内に最適な導線を引いてるので、
                <br />
                マーケティングが弱くても
                <br />
                売れる仕組みが作れます。
              </>
            }
          />
          <FeatureItem
            talkImage={Talk3}
            charaImage={Character3}
            description={
              <>
                豊富なテンプレートにより、
                <br className="xl:hidden" />
                自分らしい
                <br className="hidden sm:block" />
                リンクまとめが
                <br className="sm:hidden" />
                簡単に作成できます。
              </>
            }
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-center max-w-full text-center mt-14">
          <GradientButton
            title="無料で新規アカウント作成"
            backgroundColor="#000"
            textColor="#fff"
            onClick={toSignUp}
          />
        </div>
      </div>
    </section>
  )
}

export default Feature
