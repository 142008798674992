import React from 'react'
import CaseStudyList from '@/components/lp/CaseStudyList'
import BlockTitleWithSideLine from '@/components/lp/BlockTitleWithSideLine'
import GradientButton from '@/components/lp/GradientButton'

import { LpCaseStudy } from '@/types/LpCaseStudy'

import type { NextPage } from 'next'

type props = {
  lpCaseStudies: LpCaseStudy[]
  className?: string
}

const CaseStudy: NextPage<props> = ({ lpCaseStudies, className = '' }) => {
  const MIN_LP_CASE_STUDY_COUNT = 2
  if (lpCaseStudies.length < MIN_LP_CASE_STUDY_COUNT) {
    return null
  }

  const handleClick = () => {
    window.open('https://media.fanme.link/696', '_blank', 'noopener,noreferrer')
  }

  return (
    <section className={`w-full bg-[#f2f2f2] py-8 md:py-16 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-start justify-start gap-[32px] max-w-full mq800:gap-[16px]">
          <BlockTitleWithSideLine
            title="FANME Case Study"
            subTitle="機能紹介/クリエイターコメント"
          />
          <div className="w-full flex justify-center items-center">
            <div className="w-full mx-auto">
              <CaseStudyList lpCaseStudies={lpCaseStudies} />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-center max-w-full text-center mt-14">
          <GradientButton title="もっと見る" onClick={handleClick} />
        </div>
      </div>
    </section>
  )
}

export default CaseStudy
