import React from 'react'
import Image, { StaticImageData } from 'next/image'
import type { NextPage } from 'next'

export type BannerListItemType = {
  src: string
  alt: string
  width?: number
  height?: number
  url: string
  isExternal?: boolean
}

const BannerListItem: NextPage<BannerListItemType> = ({
  src,
  alt,
  width = 600,
  height = 200,
  url,
  isExternal = false,
}) => {
  return (
    <a
      href={url}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      className="w-full flex flex-row flex-wrap justify-center items-center gap-4"
    >
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        objectFit="cover"
        className="rounded-[16px]"
      />
    </a>
  )
}

export default BannerListItem
