import React from 'react'
import CaseStudyListItem from '@/components/lp/CaseStudyListItem'

import { LpCaseStudy } from '@/types/LpCaseStudy'

type CaseStudyListProps = {
  lpCaseStudies: LpCaseStudy[]
}

const CaseStudyList: React.FC<CaseStudyListProps> = ({ lpCaseStudies }) => {
  return (
    <div className="w-full flex flex-row justify-center lg:justify-between items-center flex-wrap gap-8 md:gap-4">
      {lpCaseStudies.map(caseStudy => (
        <CaseStudyListItem
          key={caseStudy.id}
          imageUrl={caseStudy.image_url}
          alt={caseStudy.alt}
          name={caseStudy.name}
          navigationUrl={caseStudy.navigation_url}
        />
      ))}
    </div>
  )
}

export default CaseStudyList
