import React from 'react'
import BannerList from '@/components/lp/BannerList'
import type { LpBanner } from '@/types/LpBanner'
import type { NextPage } from 'next'

export type BannerProps = {
  className?: string
  lpBanners: LpBanner[]
}

const Banner: NextPage<BannerProps> = ({ className = '', lpBanners }) => {
  const EXACT_LP_BANNER_COUNT = 2
  if (lpBanners.length !== EXACT_LP_BANNER_COUNT) {
    return null
  }
  return (
    <section className={`w-full py-8 md:py-10 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8">
        <BannerList lpBanners={lpBanners} />
      </div>
    </section>
  )
}

export default Banner
