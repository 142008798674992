import React from 'react'
import Image from 'next/image'
import Router from 'next/router'
import getConfig from 'next/config'
import GradientButton from '@/components/lp/GradientButton'
import CtaBackground from '@/assets/img/lp/CtaBackground.webp'
import Cta1 from '@/assets/img/lp/Cta1.webp'
import { event as gevent } from '@/pages/api/gtag'
import type { NextPage } from 'next'

const { publicRuntimeConfig } = getConfig()

type props = {
  className?: string
}

const CTA: NextPage<props> = ({ className = '' }) => {
  const toSignUp = () => {
    gevent({
      action: 'lp_newcreate',
      category: 'register',
      transport: 'beacon',
      cb: () => {
        Router.push(`${publicRuntimeConfig.AUTH_URL}?proc=signup`)
      },
    })
  }

  return (
    <section
      className={`self-stretch flex flex-col items-center justify-start py-12 md:py-16 px-[20px] box-border relative gap-[40px] max-w-full text-center text-[24px] text-WHITE font-sansJP mq800:gap-[20px]mq800:box-border ${className}`}
    >
      <div className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]">
        <Image src={CtaBackground} alt="CTA Background" layout="fill" objectFit="cover" priority />
      </div>
      <div className="w-[500px] flex flex-row items-start justify-start py-[0px] px-[10px] box-border max-w-full relative z-[1]">
        <Image src={Cta1} alt="CTA Image" width={500} layout="responsive" />
      </div>
      <div className="self-stretch flex flex-row items-start justify-center max-w-full text-center mt-14">
        <GradientButton
          title="無料で新規アカウント作成"
          backgroundColor="#000"
          textColor="#fff"
          onClick={toSignUp}
        />
      </div>
    </section>
  )
}

export default CTA
