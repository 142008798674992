import React, { useEffect } from 'react'
import getConfig from 'next/config'
import { GetServerSideProps } from 'next'
import AppHead from '@/components/templates/AppHead'

import Hero from '@/components/lp/section/hero'
import About from '@/components/lp/section/about'
import Banner from '@/components/lp/section/banner'
import Feature from '@/components/lp/section/feature'
import Contact from '@/components/lp/section/contact'
import CaseStudy from '@/components/lp/section/caseStudy'
import CTABlock from '@/components/lp/section/cta'
import SNS from '@/components/lp/section/sns'

import { fetchFanmeData } from '@/libs/fanme_backend'

import { LpBanner } from '@/types/LpBanner'
import { LpCaseStudy } from '@/types/LpCaseStudy'

import type { NextPage } from 'next'

interface HomeProps {
  lpBanners: LpBanner[]
  lpCaseStudies: LpCaseStudy[]
}

const { publicRuntimeConfig } = getConfig()

const Home: NextPage<HomeProps> = ({ lpBanners, lpCaseStudies }) => {
  const watchScroll = () => {
    const basePosition = 200
    const scrollPosition = window.scrollY
    const pageTopBtn = document.querySelector('.page-top-button')
    if (pageTopBtn) {
      if (basePosition <= scrollPosition) {
        pageTopBtn.classList.add('active')
      } else {
        pageTopBtn.classList.remove('active')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', watchScroll)
    return () => {
      window.removeEventListener('scroll', watchScroll)
    }
  }, [])

  return (
    <>
      <AppHead
        creatorName={null}
        title={'FANME（ファンミー） | ファンとつながるクリエイター活動まとめサービス'}
        pageTitle={'FANME（ファンミー） | ファンとつながるクリエイター活動まとめサービス'}
        description={`FANMEは、あなたの活動まとめる「リンクまとめサービス」です。\n複数のSNSや様々な記事を、無料で簡単にまとめることができます✨\nあなただけのページを今すぐ作ろう！`}
        headerImage={'https://asset-fanme-link.s3.ap-northeast-1.amazonaws.com/meta/OGP2023.png'}
        url={`${publicRuntimeConfig.FRONT_URL}`}
        isFBPixelMeta={true}
        creatorId={null}
        creatorIcon={null}
      />
      <div className="w-full relative bg-WHITE overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] text-center text-[12px] text-[#6a6969] font-[Inter]">
        <Hero />
        <Banner lpBanners={lpBanners} />
        <About />
        <Feature />
        <CaseStudy lpCaseStudies={lpCaseStudies} />
        <CTABlock />
        <Contact />
        <SNS />
      </div>
    </>
  )
}

const getLpBanners = async (): Promise<LpBanner[]> => {
  try {
    const response = await fetchFanmeData(`${publicRuntimeConfig.API_BASE_URL}/lp_banners`, {
      method: 'get',
    })
    const banners = response.lp_banners
    return banners.length === 2 ? banners : []
  } catch (error) {
    console.error('Error getting LP banners:', error)
    return []
  }
}

const getLpCaseStudies = async (): Promise<LpCaseStudy[]> => {
  try {
    const response = await fetchFanmeData(`${publicRuntimeConfig.API_BASE_URL}/lp_case_studies`, {
      method: 'get',
    })
    const caseStudies = response.lp_case_studies
    return caseStudies.length >= 2 ? caseStudies : []
  } catch (error) {
    console.error('Error getting LP case studies:', error)
    return []
  }
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const [lpBanners, lpCaseStudies] = await Promise.all([getLpBanners(), getLpCaseStudies()])

    return {
      props: {
        lpBanners,
        lpCaseStudies,
      },
    }
  } catch (error) {
    console.error('Error getting LP data:', error)
    return {
      props: {
        lpBanners: [],
        lpCaseStudies: [],
      },
    }
  }
}

export default Home
