import React from 'react'
import Image from 'next/image'
import VerticalTitleLine from '@/assets/img/lp/VerticalTitleLine.webp'
import VerticalTitleLinePurple from '@/assets/img/lp/VerticalTitleLinePurple.webp'
import VerticalTitleLineBlue from '@/assets/img/lp/VerticalTitleLineBlue.webp'
import VerticalTitleLineGray from '@/assets/img/lp/VerticalTitleLineGray.webp'
import type { NextPage } from 'next'

type LineColor = 'default' | 'purple' | 'blue' | 'gray'

export type FrameComponentType = {
  title?: string
  subTitle?: string
  lineColor?: LineColor
  subTitleColor?: string
  variant?: 'default' | 'large' | 'small'
  customTitleClass?: string
  customSubTitleClass?: string
}

const BlockTitleWithSideLine: NextPage<FrameComponentType> = ({
  title,
  subTitle,
  lineColor = 'default',
  subTitleColor = '#222',
  variant = 'default',
  customTitleClass = '',
  customSubTitleClass = '',
}) => {
  const getLineImage = () => {
    switch (lineColor) {
      case 'purple':
        return VerticalTitleLinePurple
      case 'blue':
        return VerticalTitleLineBlue
      case 'gray':
        return VerticalTitleLineGray
      default:
        return VerticalTitleLine
    }
  }

  const getTitleClass = () => {
    switch (variant) {
      case 'large':
        return 'text-[40px] sm:text-[48px]'
      case 'small':
        return 'text-[18px] sm:text-[18px] md:text-[18px] lg:text-[22px]'
      default:
        return 'text-[31px] sm:text-[40px]'
    }
  }

  const getSubTitleClass = () => {
    switch (variant) {
      case 'large':
        return 'text-[20px] sm:text-[24px]'
      case 'small':
        return 'text-[14px] sm:text-[14px] md:text-[14px] lg:text-[18px]'
      default:
        return 'text-[14px] sm:text-[16px]'
    }
  }

  return (
    <div className="self-stretch flex flex-row items-center justify-start gap-[14px] sm:gap-[18px] max-w-full text-[#222]">
      <div className="relative h-[60px] sm:h-[80px] w-[16px] shrink-0">
        <Image src={getLineImage()} alt="Vertical Title Line" layout="fill" objectFit="contain" />
      </div>
      <div className="flex flex-col items-start justify-center">
        <h2
          className={`${getTitleClass()} ${customTitleClass} font-black my-0 font-sansJP tracking-[0.03em]`}
        >
          {title}
        </h2>
        <b
          className={`${getSubTitleClass()} ${customSubTitleClass} tracking-[0.03em] leading-[1.5] font-sansJP z-[1]`}
          style={{ color: subTitleColor }}
        >
          {subTitle}
        </b>
      </div>
    </div>
  )
}

export default BlockTitleWithSideLine
