import React from 'react'
import Image from 'next/image'
import HeroBackgroundPC from '@/assets/img/lp/HeroBackgroundPC.webp'
import HeroBackgroundSP from '@/assets/img/lp/HeroBackgroundSP.webp'
import FANME_PC from '@/assets/img/lp/HeroPC.webp'
import FANME_SP from '@/assets/img/lp/HeroSP.webp'
import { Colors } from '@/constants/styles/color'

import {
  StyledScrollBar,
  StyledScrollBarBall,
  StyledScrollBarRange,
  StyledScrollBarTransparent,
  StyledScrollWrapper,
} from '@/components/molecules/CreatorCover/Contents/index.style'

import type { NextPage } from 'next'

type props = {
  className?: string
}

const Hero: NextPage<props> = ({ className = '' }) => {
  return (
    <section
      className={`relative w-full h-[630px] sm:h-[600px] md:h-[670px] lg:h-[680px] px-2 ${className}`}
    >
      <Image
        src={HeroBackgroundSP}
        alt="Background"
        layout="fill"
        objectFit="cover"
        quality={100}
        className="sm:hidden"
      />
      <Image
        src={HeroBackgroundPC}
        alt="Background"
        layout="fill"
        objectFit="cover"
        quality={100}
        className="hidden sm:block"
      />
      <div className="absolute inset-0 flex flex-col">
        <div className="w-full max-w-lp-hero-section flex flex-col px-4 md:px-0 mx-auto">
          <div className="relative w-full flex flex-col items-center sm:items-start sm:px-2 md:px-0">
            <Image
              src={FANME_SP}
              alt="FANME - MORE PEOPLE WANT TO KNOW YOU."
              height={390}
              className="sm:hidden mt-24 mb-4"
            />
            <Image
              src={FANME_PC}
              alt="FANME - MORE PEOPLE WANT TO KNOW YOU."
              width={912}
              className="hidden sm:block mt-36 mb-10 px-2"
            />
            <h2 className="w-full text-[24px] md:text-[40px] font-black tracking-[0.2em] leading-[36px] md:leading-[60px] text-[#222] text-left md:px-4">
              <span className="inline-block">
                <span className="bg-WHITE">ファンマネタイズ</span>
                <span className="bg-WHITE sm:bg-TRANSPARENT">を、</span>
              </span>
              <br />
              <span className="inline-block">もっと身近に、</span>
              <br className="md:hidden" />
              <span className="inline-block">もっと簡単に</span>
            </h2>

            <div className="absolute right-0 bottom-7">
              <StyledScrollWrapper>
                <div className="text-BLACK font-['DM_Sans'] text-[10px] font-semibold leading-[10px] tracking-[0.15em] text-shadow-sm text-white h-6 flex justify-center items-center -rotate-90 mb-6">
                  SCROLL
                </div>
                <StyledScrollBarRange>
                  <StyledScrollBar beforeBarColor={Colors.BLACK} />
                  <StyledScrollBarTransparent>
                    <StyledScrollBarBall ballColor={Colors.BLACK} />
                  </StyledScrollBarTransparent>
                </StyledScrollBarRange>
              </StyledScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
