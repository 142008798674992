import React from 'react'
import Image from 'next/image'
import Router from 'next/router'
import BlockTitleWithUnderLine from '@/components/lp/BlockTitleWithUnderLine'
import GradientButton from '@/components/lp/GradientButton'
import Typography from '@/components/atoms/Typography'

import About1 from '@/assets/img/lp/About1.webp'
import About2 from '@/assets/img/lp/About2.webp'
import type { NextPage } from 'next'

type props = {
  className?: string
}

const About: NextPage<props> = ({ className = '' }) => {
  return (
    <section className={`w-full bg-[#f2f2f2] py-8 md:py-16 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8">
        <BlockTitleWithUnderLine title="FANMEとは" />
        <div className="mt-12 flex flex-col md:flex-row items-center md:items-start md:justify-between gap-5 md:gap-6">
          <div className="w-full md:w-[calc(50%-12px)] aspect-[1.88/1]">
            <div className="w-full h-full flex flex-row items-stretch">
              <div className="w-[50%] bg-WHITE flex items-center justify-center p-[5%] box-border">
                <div className="relative w-full h-full">
                  <Image src={About1} alt="About 1" layout="fill" objectFit="contain" />
                </div>
              </div>
              <div className="w-[50%] [background:linear-gradient(38.38deg,_#0050d2_3.06%,_#00b9e6_50%,_#00e69b)] flex items-center justify-center p-[2px] box-border">
                <Typography variant="top-about-description" className="m-0 flex-1 relative">
                  リンクまとめ機能
                </Typography>
              </div>
            </div>
          </div>

          <div className="w-full md:w-[calc(50%-12px)] aspect-[1.88/1]">
            <div className="w-full h-full flex flex-row items-stretch">
              <div className="w-[50%] bg-WHITE flex items-center justify-center p-[5%] box-border">
                <div className="relative w-full h-full">
                  <Image src={About2} alt="About 2" layout="fill" objectFit="contain" />
                </div>
              </div>
              <div className="w-[50%] [background:linear-gradient(38.38deg,_#8c1ee6,_#ff55af_50%,_#ffc814)] flex items-center justify-center p-[2px] box-border">
                <Typography variant="top-about-description" className="m-0 flex-1 relative">
                  デジタルコンテンツ販売
                  <br />
                  &
                  <br />
                  ファンレター機能
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-row items-start justify-center max-w-full text-[32px] mt-8">
          <div className="w-[808px] flex flex-col items-start justify-start gap-[32px] max-w-full ">
            <div className="self-stretch relative tracking-[0.03em] leading-[38px] md:leading-[42px] z-[2] text-[26px] md:text-[32px] font-bold text-BLACK sm:text-center text-left">
              <div className="space-y-4 md:space-y-0">
                FANMEはファンの熱狂を、
                <br className="sm:hidden" />
                <span className="relative">
                  <span className="relative z-10">共に創っていく</span>
                  <span className="absolute bottom-0 left-0 w-full h-[0.4em] bg-FANME_YELLOW opacity-90"></span>
                </span>
                <br />
                サポートをします
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-center py-[0px] px-[20px] box-border max-w-full text-[24px]">
              <GradientButton
                title="もっと見る"
                onClick={() => {
                  Router.push('/about')
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
