import React from 'react'
import Image, { type StaticImageData } from 'next/image'
import type { NextPage } from 'next'

type CaseStudyListItemType = {
  className?: string
  name: string
  navigationUrl: string
  imageUrl: string
  alt: string
}

const CaseStudyListItem: NextPage<CaseStudyListItemType> = ({
  className = '',
  name,
  navigationUrl,
  imageUrl,
  alt,
}) => {
  return (
    <a
      href={navigationUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={`block w-full sm:w-[260px] rounded-[16px] bg-WHITE shadow-md overflow-hidden ${className} cursor-pointer transition-transform hover:scale-105`}
    >
      <div className="relative w-full aspect-[21/22]">
        <Image src={imageUrl} alt={alt} layout="fill" objectFit="cover" />
      </div>
      <div className="p-4">
        <h3 className="text-[20px] font-bold font-sansJP tracking-[0.03em] mb-0 text-center text-[#222222]">
          {name}
        </h3>
      </div>
    </a>
  )
}

export default CaseStudyListItem
