import React from 'react'
import BannerListItem from '@/components/lp/BannerListItem'
import type { LpBanner } from '@/types/LpBanner'
import type { NextPage } from 'next'
type Props = {
  lpBanners: LpBanner[]
}

const BannerList: NextPage<Props> = ({ lpBanners }) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
      {lpBanners.map(banner => (
        <div key={banner.id} className="w-full md:w-1/2 mb-4 md:mb-0">
          <BannerListItem
            src={banner.image_url}
            url={banner.navigation_url}
            alt={banner.alt || `Banner ${banner.id}`}
            width={600}
            height={200}
          />
        </div>
      ))}
    </div>
  )
}

export default BannerList
