import React from 'react'
import Router from 'next/router'
import BlockTitleWithSideLine from '@/components/lp/BlockTitleWithSideLine'
import GradientButton from '@/components/lp/GradientButton'
import type { NextPage } from 'next'

type props = {
  className?: string
}

const Contact: NextPage<props> = ({ className = '' }) => {
  return (
    <section className={`w-full bg-[#f2f2f2] py-8 md:py-16 ${className}`}>
      <div className="max-w-lp-section mx-auto px-4 sm:px-6 lg:px-8">
        <BlockTitleWithSideLine title="Contact" subTitle="お問い合わせ" />

        <div className="flex flex-col items-center justify-start gap-[48px] max-w-full mt-8">
          <div className="text-center max-w-[927px] ">
            <p className="m-0 text-BLACK text-[18px] sm:text-[20px] lg:text-[24px] leading-[1.8] font-sansJP ">
              <b>サービスに関するご質問</b>はもちろん、
              <br className="md:hidden" />
              <b>取材やメディア掲載</b>に関わる
              <br />
              お問い合わせもこちらから承ります。
            </p>
          </div>
          <div className="w-[736px] flex flex-row items-start justify-center py-[0px] px-[20px] box-border max-w-full">
            <GradientButton
              title="お問い合わせ"
              onClick={() => {
                Router.push('/contact')
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
